import React, {useEffect} from 'react';
import {getQueryParam} from "../infrastructure/getQueryParam";
import {useLocation} from "@reach/router"
import {navigate} from "gatsby";

const ThankYouPage = () => {

    const location = useLocation();

    useEffect(() => {
        const grant = getQueryParam("grant", location);
        if (!grant || grant === '') {
            navigate('/');
        }
        setTimeout(() => {
            navigate(process.env.APP_HOST + "/login?grant=" + grant);
        }, 1500)


    }, []);

    return (<div>redirecting...</div>);
};
export default ThankYouPage;
